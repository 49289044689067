<template>
  <WeContainer card="" v-if="ready">
    <Navigation
      v-on:save-form="submitForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
      v-on:change-language="changeLanguage"
    />

    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <!-- Marka Seçimi -->
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col">
                          <label
                            for="brand"
                            class="custom-label"
                            v-bind:class="{
                              'text-danger': $v.form.selected.brand.$error,
                            }"
                            >Marka</label
                          >
                        </div>
                        <div
                          class="col-auto"
                          v-if="$v.form.selected.brand.$error"
                        >
                          <div
                            class="text-danger ml-auto"
                            v-if="!$v.form.selected.brand.required"
                          >
                            <small><i>Zorunlu Alan</i></small>
                          </div>
                        </div>
                      </div>
                      <select
                        name="brand"
                        id="brand"
                        class="custom-select"
                        :class="{
                          'border-danger': $v.form.selected.brand.$error,
                        }"
                        v-model="form.selected.brand"
                      >
                        <option value="-1">Seçiniz</option>
                        <option
                          v-for="brand in brand.list"
                          v-bind:key="brand.id"
                          v-bind:value="brand.id"
                        >
                          {{ brand.name }}
                        </option>
                      </select>
                    </div>
                    <!-- ./Marka Seçimi -->

                    <!-- Model Adı -->
                    <div class="form-group mb-3">
                      <WeInput
                        label="Model Adı"
                        name="model_name"
                        v-bind:required="true"
                        v-model="form.name"
                        v-bind:error="$v.form.name.$error"
                      />
                    </div>
                    <!-- ./Model Adı -->

                    <!-- Slug -->
                    <WeSlugCheck
                      v-bind:value="form.name"
                      v-bind:id="$route.params.id"
                      v-bind:slug="form.slug"
                      v-bind:search="true"
                      v-on:slugify="changeSlug"
                      url-fragment="model"
                      type="model"
                    />
                    <!-- ./Slug -->

                    <div class="form-group">
                      <label class="custom-label">Model Durumu</label>
                      <WeSwitch v-model="form.is_active" label="Aktif" />
                    </div>
                    <WeImageUploader
                      v-model="form.image"
                      v-bind:image-required="true"
                      v-bind:fixedMetaTitle="form.name"
                      v-bind:fixedMetaAlt="form.name"
                      title="Marka Logosu"
                      ref="imageuploader"
                    />
                    <WeImageUploader
                      v-model="form.mobile_image"
                      v-bind:fixedMetaTitle="form.name"
                      v-bind:fixedMetaAlt="form.name"
                      title="Marka Mobil Logosu"
                      ref="imageuploader"
                    />
                    <WeImageUploader
                      v-model="form.cover_image"
                      v-bind:fixedMetaTitle="form.name"
                      v-bind:fixedMetaAlt="form.name"
                      title="Model Kapak Fotosu"
                      ref="imageuploader"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group mb-3">
                      <WeInput
                        label="Başlık"
                        name="model_title"
                        v-model="form.title"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <WeInput
                        label="Alt Başlık"
                        name="model_sub_title"
                        v-model="form.sub_title"
                      />
                    </div>
                    <WeDescription
                      v-model="form.description"
                      v-bind:access-token="session.accessToken"
                      v-bind:name="'model'"
                      v-bind:title="$t('description')"
                    />
                    <div class="form-group mb-3">
                      <WeInput
                        label="Embed Url"
                        name="model_embed"
                        v-model="form.embed_url"
                      />
                    </div>

                    <WeMetaData v-model="form.meta" />

                    <WeSeoBar
                      v-bind:focus-keyword="form.name"
                      v-bind:metaTitle="form.meta.title"
                      v-bind:metaDescription="form.meta.description"
                      v-bind:description="form.description.text"
                      v-bind:images="form.image.files"
                      v-bind:slug="form.slug"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: false,
      errors: [],
      form: {
        slug: null,
        name: null,
        is_active: true,
        description: {
          text: null,
          show: false,
          place: "before",
        },
        selected: {
          brand: -1,
        },
        embed_url: null,
        title: null,
        sub_title: null,
        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },
        image: {
          files: [],
          filesData: null,
        },
        mobile_image: {
          files: [],
          filesData: null,
        },
        cover_image: {
          files: [],
          filesData: null,
        },
      },
      cleanRequest: {},
      tmpLang: null,
    };
  },
  components: {
    Navigation,
  },
  validations: {
    form: {
      name: {
        required,
      },
      selected: {
        brand: {
          required: function () {
            return (
              this.form.selected.brand !== "-1" &&
              this.form.selected.brand !== -1
            );
          },
        },
      },
    },
  },
  methods: {
    ...mapActions("model", ["create", "update", "getById"]),
    ...mapActions({
      getBrandList: "brand/getList",
    }),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      let imageValid = this.$refs.imageuploader.$refs.single.$v;
      imageValid.$touch();

      if (this.$v.$invalid || imageValid.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      if (this.$route.params.id) {
        if (this.tmpLang == this.model.lang) {
        this.cleanRequest = helper.difference(this.form, this.cleanData, [
            "image",
            "selected",
            "title",
            "description",
            "sub_title"
          ]);
        } else this.cleanRequest = this.form;
        this.updateModel();
      } else {
        this.addModel();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Model listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addModel() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateModel() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getModelById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          lang: this.model.lang,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
          },
          onFinish: () => {
            this.hideLoader();
          },
        });
      } else {
        this.hideLoader();
      }
    },
    changeLanguage(newVal) {
      this.model.lang = newVal;
      this.ready = false;
      this.loadModel();
    },
    loadModel() {
      this.getBrandList({
        lang: this.model.lang,
        onSuccess: (result) => {
          this.brands = result.data.items;
        },
        onFinish: () => {
          this.getModelById();
        },
      });
    },
    hideLoader(timeout = 750) {
      setTimeout(() => {
        this.ready = true;
      }, timeout);
    },
    redirectToList() {
      this.$router.push("/models");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["model", "shared", "session", "brand"]),
    getTitle() {
      return this.$route.params.id ? "MODEL DÜZENLE" : "MODEL EKLE";
    },
    checkPermission() {
      return permission.check("model", "u");
    },
  },
  mounted() {
    this.loadModel();
    this.tmpLang = this.model.lang;
  },
};
</script>
